import React, { useEffect, useState } from 'react'

import Carousel from 'react-multi-carousel'
import { addDays, format } from 'date-fns';
import axios from 'axios';
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'
import 'react-multi-carousel/lib/styles.css'

import debatesBoxImg from '../images/blm-protests.png'

import '../scss/page-debates.scss'
import { GETURL, LOADER } from '../utils/constants';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const categoryPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [allDebates, setAllDebates] = useState([])

  useEffect(() => {
    async function getAllDebates() {
      try {
        const requestAllDebates = await axios.get(
          `${GETURL}/classes`, {
            params: {
              top50: false,
              order: 'asc',
              orderby: 'date',
            },
          },
        );
        // Primeiro: data = axios; Segundo: data : endpoint
        setAllDebates(requestAllDebates.data.debates)
        setLoading(false);
      } catch (error) {
        console.log('Erro: ', error.message)
      }
    }

    getAllDebates()
  }, [])
  return (
    <Layout>
      <Seo title="Debates | Talk of the Town" />

      <Hero text="Debates" />

      <div className="categories">
        <SliderDebates sectionTitle="Artes">
          <Carousel
            swipeable={false}
            draggable={false}
            arrows
            showDots
            responsive={responsive}
            ssr // means to render carousel on server-side.
            infinite
            autoPlaySpeed={1000}
            keyBoardControl
            containerClass="carousel-container row"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            slidesToSlide={3}
          >
            {isLoading && (
              <div className="loadingSpinner"><img src={LOADER} alt="Carregando" /></div>
            )}
            {allDebates.map((item) => {
              const {
                _id, get_image, date, title, description,
              } = item;
              return (
                <DebatesBox
                  key={_id}
                  widthClass="col-12"
                  background={get_image}
                  dateHour={format(new Date(date), 'dd/MM/yyyy')}
                  title={title}
                  description={description}
                  buttonUrl={`single/${_id}`}
                  buttonText="Saiba mais"
                  wordLimit="300"
                />
              )
            })}
          </Carousel>
        </SliderDebates>
      </div>
    </Layout>
  )
}

export default categoryPage
